import { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Typography,
  Chip,
  Button,
  Divider,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import StructureMapping, { StructureMap } from "../components/StructureMapping";
import {
  useAutomationIntegrationApiV1ListTemplatesQuery,
  useAutomationIntegrationApiV1ListTemplateDetailsQuery,
  useAutomationIntegrationApiV1GetStructuresQuery,
  useAutomationIntegrationApiV1RequestStructuresMutation,
  useAutomationIntegrationApiV1RequestPlanGenerationMutation,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
  OrderStatus,
  useAutomationIntegrationApiV1ListMachinesQuery,
  useWebApiV1RelatedPrescriptionsQuery,
} from "@providers/hop-ord-server/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Cancel, CheckCircle, ChevronLeft } from "@mui/icons-material";
import { StructureStatus } from "@enums";

interface Props {
  orderId: number;
}

interface StructureObj {
  name: string;
  type: string;
}

const PlanningTemplateApi = ({ orderId }: Props): JSX.Element => {
  const navigate = useNavigate();

  const [structureState, setStructureState] = useState<StructureMap[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [relatedPrescription, setRelatedPrescription] = useState("");
  const [relatedPrescriptionId, setRelatedPrescriptionId] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [structuresRetrieved, setStructuresRetrieved] = useState(false);
  const [structuresRequested, setStructuresRequested] = useState(false);

  const [updateOrder] = useWebApiV1UpdateOrderMutation();

  /** List Data query */
  const { data: machines, isLoading: machinesLoading } =
    useAutomationIntegrationApiV1ListMachinesQuery({
      orderId,
    });

  useEffect(() => {
    // Default to the first machine if there is only one
    if (machines?.length === 1 && !selectedMachine) {
      setSelectedMachine(machines[0].name);
    }
  }, [machines]);

  // Temp options while the list data is not available
  const machineOptions = machines?.map((data) => data.name) || [];

  const { data: order, isLoading: orderLoading } = useWebApiV1OrderQuery({
    orderId,
  });

  // Site query (to get names and ID's for related prescriptions)
  const { data: relatedPrescriptions } = useWebApiV1RelatedPrescriptionsQuery({
    orderId: orderId.toString(),
  });

  const rtStructExists = order?.rtstructStatus === StructureStatus.COMPLETE;
  const ctExists = order?.ctStatus === StructureStatus.COMPLETE;

  const showAutomation = rtStructExists && ctExists;

  const warningText =
    !ctExists && !rtStructExists
      ? "Missing CT and RT structure set."
      : !ctExists
        ? "Missing CT."
        : !rtStructExists
          ? "Missing RT structure set."
          : "";

  const { data: templateNames, isLoading: templateNamesLoading } =
    useAutomationIntegrationApiV1ListTemplatesQuery({ orderId });

  const { data: template } =
    useAutomationIntegrationApiV1ListTemplateDetailsQuery(
      selectedTemplate
        ? { orderId, templateName: selectedTemplate }
        : skipToken,
    );

  const [sendAvailableStructuresRequest] =
    useAutomationIntegrationApiV1RequestStructuresMutation();
  const [sendPlan] =
    useAutomationIntegrationApiV1RequestPlanGenerationMutation();

  const setOrderStatus = (status: OrderStatus): Promise<any> => {
    return updateOrder({
      orderId,
      orderIn: {
        status,
      },
    });
  };

  const relatedPrescriptionOptions = relatedPrescriptions?.map(
    (prescription) => ({
      label: prescription.relatedPrescriptionName,
      id: prescription.relatedPrescriptionId,
    }),
  );

  const templateOptions = [
    "",
    ...(templateNames?.map(
      (templateName: { name: string }) => templateName.name,
    ) || []),
  ];

  const { data: availableStructures, isLoading: availableStructuresLoading } =
    useAutomationIntegrationApiV1GetStructuresQuery(
      {
        orderId,
      },
      {
        pollingInterval: 500,
        skip: !selectedTemplate || structuresRetrieved,
      },
    );

  useEffect(() => {
    if (
      !structuresRetrieved &&
      !structuresRequested &&
      !availableStructuresLoading &&
      selectedTemplate
    ) {
      sendAvailableStructuresRequest({ orderId });
      setStructuresRequested(true);
    }

    if (availableStructures?.structures?.length) {
      setStructuresRetrieved(true);
      return;
    }
  }, [
    structuresRetrieved,
    availableStructuresLoading,
    structuresRequested,
    availableStructures,
    orderId,
    selectedTemplate,
  ]);

  useEffect(() => {
    if (relatedPrescriptions?.length === 1 && !relatedPrescription) {
      // Automatically select the first related prescription and id if there is only one
      setRelatedPrescription(relatedPrescriptions[0].relatedPrescriptionName);
      setRelatedPrescriptionId(
        relatedPrescriptions[0].relatedPrescriptionId.toString(),
      );
    }
  }, [relatedPrescriptions]);

  const scrollToFirstInvalidField = (structures: StructureMap[]) => {
    if (!selectedMachine) {
      const machineElement = document.getElementById("selected-machine");
      machineElement?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else {
      const firstInvalidStructure = structures.find((s) => !s.isValid);
      const firstInvalidElement = document.getElementById(
        `planning-template-value-${firstInvalidStructure?.id}`,
      );
      firstInvalidElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const runValidation = (structures: StructureMap[]) => {
    const allValid = structures.every((s) => {
      return !(s.mandatory && !s.values.length);
    });

    const updatedStructures = structures.map((structure) => {
      const isInvalid = structure.mandatory && !structure.values.length;
      return {
        ...structure,
        isValid: !isInvalid,
      };
    });

    setStructureState(updatedStructures);
    return (
      allValid &&
      !!selectedMachine &&
      !!selectedTemplate &&
      !!relatedPrescription
    );
  };

  const submitPlanGeneration = async () => {
    setHasSubmitted(true);
    if (!runValidation(structureState)) {
      scrollToFirstInvalidField(structureState);
      return;
    }

    // Submit has been clicked and / or validation has run successfully. Submit form.
    const structureMap: StructureObj[] = [];
    structureState.forEach((s) => {
      if (s.values) {
        const map = s.values.map((value) => ({
          name: s.name,
          type: value,
        }));
        structureMap.push(...map);
      }
    });

    sendPlan({
      orderId,
      planGenerationAttemptIn: {
        template: selectedTemplate,
        structures: structureMap,
        machine: selectedMachine,
        related_prescription: relatedPrescription,
        related_prescription_id: relatedPrescriptionId,
      },
    }).then(() => {
      setHasSubmitted(true);
      setOrderStatus("sent_to_automation");
      navigate("automated");
    });
  };

  if (templateNamesLoading || machinesLoading) return <></>;

  return (
    <Stack width={1} height={1} marginBottom={3} gap={2}>
      <Stack direction="row" gap={1} paddingTop={1}>
        <Chip
          color={orderLoading ? "secondary" : ctExists ? "success" : "error"}
          icon={
            orderLoading ? (
              <CircularProgress size={18} />
            ) : ctExists ? (
              <CheckCircle />
            ) : (
              <Cancel />
            )
          }
          variant="outlined"
          label="CT Exists"
        />
        <Chip
          color={
            orderLoading ? "secondary" : rtStructExists ? "success" : "error"
          }
          icon={
            orderLoading ? (
              <CircularProgress size={18} />
            ) : rtStructExists ? (
              <CheckCircle />
            ) : (
              <Cancel />
            )
          }
          variant="outlined"
          label="RTStruct Exists"
        />
      </Stack>
      {!showAutomation && (
        <Stack gap={2}>
          <Typography>
            Unable to start planning automation. {warningText}
          </Typography>
          <Button
            variant="outlined"
            sx={{ width: "max-content" }}
            startIcon={<ChevronLeft />}
            onClick={() => navigate("/commandcenter")}
          >
            Back to command center
          </Button>
        </Stack>
      )}
      {showAutomation && (
        <Stack gap={2} width={1}>
          <FormControl
            sx={{ paddingTop: 1, width: "50%" }}
            error={hasSubmitted && !relatedPrescription}
          >
            <Autocomplete
              disableClearable
              options={relatedPrescriptionOptions || []}
              value={{ label: relatedPrescription, id: relatedPrescriptionId }}
              fullWidth
              onChange={(
                _: any,
                newValue: { label: string; id: string } | null,
              ) => {
                setRelatedPrescription(newValue?.label || "");
                setRelatedPrescriptionId(newValue?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={hasSubmitted && !relatedPrescription}
                  label="Related prescription *"
                />
              )}
            />
            {hasSubmitted && !relatedPrescription && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
          <Typography variant="subtitle1">Plan Configuration</Typography>
          <Stack direction="row" gap={2}>
            <FormControl
              sx={{ width: 1 }}
              error={hasSubmitted && !selectedTemplate}
            >
              <Autocomplete
                options={templateOptions || []}
                value={selectedTemplate}
                fullWidth
                onChange={(_: any, newValue: string | null) => {
                  setSelectedTemplate(newValue || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={hasSubmitted && !selectedTemplate}
                    label="Automated template *"
                  />
                )}
              />
              {hasSubmitted && !selectedTemplate && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{ width: 1 }}
              error={hasSubmitted && !selectedMachine}
            >
              <InputLabel id="selected-machine-label">Machine *</InputLabel>
              <Select
                id="selected-machine"
                data-testid="selected-machine"
                value={selectedMachine}
                labelId="selected-machine"
                label="Machine *"
                onChange={(e) => setSelectedMachine(e.target.value)}
              >
                {machineOptions.map((machine) => (
                  <MenuItem key={machine} value={machine}>
                    {machine}
                  </MenuItem>
                ))}
              </Select>
              {hasSubmitted && !selectedMachine && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
          </Stack>
          {(!templateNames?.length || !machineOptions?.length) && (
            <Stack direction="row" gap={1}>
              <Typography color="error">
                No templates and/or machines found.
              </Typography>
            </Stack>
          )}
          {selectedTemplate && (
            <StructureMapping
              template={template}
              availableStructures={availableStructures}
              hasSubmitted={hasSubmitted}
              setHasSubmitted={setHasSubmitted}
              setStructureState={setStructureState}
              structureState={structureState}
              runValidation={runValidation}
            />
          )}
          <Divider />
          <Stack direction="row" width={1} justifyContent="flex-end" gap={2}>
            <Button
              data-testid={"planning-template-back"}
              size="medium"
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              data-testid={"planning-template-submit"}
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => submitPlanGeneration()}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default PlanningTemplateApi;
