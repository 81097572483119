import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCredentials } from "./features/auth/auth0Slice";
import { useEffect } from "react";
import {
  RODashboard,
  OrderPage,
  ErrorPage,
  FulfillmentPage,
  SentryErrorPage,
  RoOrderTrackerPage,
  DosiOrderTrackerPage,
  CommandCenter,
  DosiAutomationTrackerPage,
  DemoDosiOrderTrackerPage,
  DemoCommandCenter,
} from "@pages";
import DosimetristDashboard from "./pages/Dashboard/DosimetristDashboard";
import { sec } from "./app/security";
import { Route, Routes } from "react-router-dom";
import { Role, Feature } from "./enums";
import { Navigate } from "react-router-dom";
import Layout from "./pages/Layout/layout";
import { CssBaseline, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import LogoutOnInactivity from "@pages/LogoutOnInactivity/component/LogoutOnInactivity";
import MuiXLicense from "@utils/MuiXLicense";
import { ErrorAlert } from "@components";
import { useWebApiV1GetUserFeatureSwitchesQuery } from "@providers/hop-ord-server/api";
import { jwtDecode } from "jwt-decode";
import { DEMO_TENANTS } from "./constants";

const INACTIVITY_TIMEOUT = parseInt(
  process.env.VITE_INACTIVITY_TIMEOUT_MINUTES || "60",
);

const userHomeRedirect = (roles: string[], accessToken: boolean): string => {
  // Return home page url for user based on role (to be redirected post login)
  // Multi roles will redirect to /order for now
  const { data: featureSwitchData, isLoading } =
    useWebApiV1GetUserFeatureSwitchesQuery(undefined, { skip: !accessToken });

  if (!accessToken || isLoading) {
    return "";
  }

  const automationIntegration = featureSwitchData?.find(
    (feature) => feature.name === Feature.AUTOMATION_INTEGRATION,
  );
  const newCommandCenter = featureSwitchData?.find(
    (feature) => feature.name === Feature.NEW_COMMAND_CENTER,
  );

  const redirectTo = () => {
    if (roles?.includes(Role.DOSIMETRIST)) {
      if (automationIntegration) {
        if (newCommandCenter) {
          return "/commandcenter";
        }
        return "/demo/commandcenter";
      }
      return "/fulfillment";
    }
    if (roles?.includes(Role.RO)) {
      return "/order";
    }
    return "*";
  };

  return redirectTo();
};

const App = () => {
  const {
    user,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const updateAccessToken = async () => {
      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      sec.setAccessTokenSilently(getAccessTokenSilently);
      // Send only user sub which is unique to user in Auth0 and not PII to Sentry
      const sentry_name = `${user?.sub} | ${user?.name}`;
      Sentry.setUser({ username: sentry_name });
      const permissions = token ? jwtDecode<any>(token)?.permissions : [];
      const credentials = {
        user: user?.name || "",
        token: token,
        idToken: idToken?.__raw || "",
        roles: permissions?.filter((role: string) => role.startsWith("role:")),
        tenants: permissions?.filter((tenant: string) =>
          tenant.startsWith("tenant:"),
        ),
      };
      dispatch(setCredentials(credentials));
    };
    if (user) {
      updateAccessToken();
    }
  }, [user, dispatch, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (!user && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [user, isLoading, loginWithRedirect]);

  const { roles, isAuthenticated: accessToken } = useAppSelector(
    (state) => state.auth,
  );

  const isDemoUser = useAppSelector(
    (state) =>
      state.auth.tenants.filter((value) => DEMO_TENANTS.includes(value))
        .length > 0,
  );

  const showSkeleton = !user || !accessToken;

  const redirectTo = userHomeRedirect(roles, accessToken);

  const alertOpen = useAppSelector((state) => state.settings.alertOpen);

  return (
    <CssBaseline>
      <Layout
        user={user}
        accessToken={accessToken}
        notificationsEnabled={isDemoUser}
      >
        <ErrorAlert open={alertOpen} />
        <MuiXLicense />
        {showSkeleton ? (
          <Stack data-testid="main-app-loading"></Stack>
        ) : (
          <>
            {/* Logout and redirect to inactivity page after 60 minutes of inactivity */}
            <LogoutOnInactivity idleMinutes={INACTIVITY_TIMEOUT} />
            <Routes>
              <Route path="/" element={<Navigate to={redirectTo} />} />
              <Route path="/commandcenter" element={<CommandCenter />} />
              <Route
                path="/fulfillment/:orderId/automation/*"
                element={<DosiAutomationTrackerPage />}
              />
              {/* Demo pages below, to be removed when final command center is implemented */}
              <Route path="/demo">
                <Route path="commandcenter" element={<DemoCommandCenter />} />
                <Route
                  path="fulfillment/:orderId/tracker"
                  element={<DemoDosiOrderTrackerPage />}
                />
              </Route>
              <Route path="/order" element={<RODashboard />} />
              <Route path="/order/:orderId" element={<OrderPage />} />
              <Route
                path="/order/:orderId/tracker"
                element={<RoOrderTrackerPage />}
              />
              <Route path="/fulfillment" element={<DosimetristDashboard />} />
              <Route
                path="/fulfillment/:orderId"
                element={<FulfillmentPage />}
              />
              <Route
                path="fulfillment/:orderId/tracker"
                element={<DosiOrderTrackerPage />}
              />
              <Route path="/sentry/error" element={<SentryErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </>
        )}
      </Layout>
    </CssBaseline>
  );
};

export default App;
