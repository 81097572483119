import { useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  CircularProgress,
  Checkbox,
  MenuItem,
} from "@mui/material";
import {
  TemplateOptionSerialised,
  StructureSetResponse,
} from "@providers/hop-ord-server/api";

const CARD_HEADER_SUBTITLE =
  "Structures marked with * are mandatory. Unmapped template structure objectives will be removed for planning automation upon submission. ";

interface Props {
  hasSubmitted: boolean;
  setHasSubmitted: (hasSubmitted: boolean) => void;
  template?: TemplateOptionSerialised;
  availableStructures?: StructureSetResponse | null;
  setStructureState: (structures: StructureMap[]) => void;
  structureState: StructureMap[];
  runValidation: (structures: StructureMap[]) => boolean;
}

export interface StructureMap {
  id: number;
  name: string;
  mandatory: boolean;
  values: string[];
  isValid: boolean;
}

const StructureMapping = ({
  availableStructures,
  template,
  setHasSubmitted,
  setStructureState,
  structureState,
  runValidation,
}: Props): JSX.Element => {
  const structureOptions = availableStructures?.structures?.map(
    (structureName: { name: string }) => structureName.name,
  );
  /*
  Each template has a set of structures that the user must map to the available patient structures
  from the TPS provided by automation.
  When a template is selected, loop through the each template structure and use it's provided
  regex to automatically map patient structures to template structures.
  */
  useEffect(() => {
    // reset state on template change
    setHasSubmitted(false);

    const templateMap: StructureMap[] = [];
    template?.data?.forEach((t, idx) => {
      const regexes = typeof t.regex === "string" ? [t.regex] : t.regex;
      let initialMappedStructs: string[] = [];

      for (const regex of regexes ?? []) {
        // Only use the first regex that matches
        const mappedStructs = availableStructures?.structures
          ?.filter((option) => {
            return new RegExp(regex, "gi").test(option?.name);
          })
          .map((option) => option.name);

        if (mappedStructs && mappedStructs.length > 0) {
          initialMappedStructs = mappedStructs;
          break;
        }
      }

      templateMap.push({
        id: idx,
        name: t.name,
        mandatory: t.mandatory,
        values: initialMappedStructs,
        isValid: true,
      });
    });

    setStructureState(templateMap);
  }, [template, availableStructures]);

  const handleMappingChange = (id: number, newValue: string[]) => {
    const newStructures = structureState.map((structs) => {
      return structs.id === id
        ? {
            ...structs,
            values: newValue,
            isValid: !(!newValue.length && structs.mandatory),
          }
        : structs;
    });
    setStructureState(newStructures);
    runValidation(newStructures);
  };

  return (
    <Stack gap={2}>
      <Stack maxHeight="100%" height={1} width={1} marginBottom={1}>
        <Card>
          <CardHeader
            title="Map Structure"
            subheader={CARD_HEADER_SUBTITLE}
            titleTypographyProps={{ variant: "subtitle1" }}
            subheaderTypographyProps={{ variant: "body2" }}
          />
          <CardContent>
            {availableStructures ? (
              <Stack gap={2}>
                <Stack direction="row">
                  <Typography sx={{ width: "100%" }}>
                    Template Structure
                  </Typography>
                  <Typography sx={{ width: "100%" }}>
                    Available Structure
                  </Typography>
                </Stack>
                <Divider />
                <Stack gap={2} data-testid="structure-table">
                  {structureState?.map((ts) => {
                    return (
                      <Stack direction="row" key={ts.id}>
                        <Typography
                          data-testid={`planning-template-key-${ts.id}`}
                          lineHeight={3}
                          sx={{ width: "100%" }}
                          variant="body2"
                        >
                          {ts?.name} {ts?.mandatory && "*"}
                        </Typography>
                        <Autocomplete
                          id={`planning-template-value-${ts.id}`}
                          data-testid={`planning-template-value-${ts.id}`}
                          options={structureOptions || []}
                          fullWidth
                          size="small"
                          multiple
                          renderInput={(params) => (
                            <TextField
                              error={!ts.isValid}
                              helperText={
                                !ts.isValid && "This field is required"
                              }
                              {...params}
                              label=""
                            />
                          )}
                          value={ts.values}
                          defaultValue={[]}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem {...props} selected={selected}>
                              <Checkbox checked={selected} />
                              {option}
                            </MenuItem>
                          )}
                          onChange={(_, mappedValue) => {
                            handleMappingChange(ts.id, mappedValue);
                          }}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            ) : (
              <Stack alignItems="center" gap={1}>
                <CircularProgress color="primary" />
                <Typography variant="caption" color="text.secondary">
                  Retrieving RTStruct from TPS...
                </Typography>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};

export default StructureMapping;
