import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { WarningAmberOutlined, ErrorOutline } from "@mui/icons-material";
import { PlanningWarning } from "@providers/hop-ord-server/api";

export interface AutomationEvent {
  updatedAt: string;
  eventDetails: string;
  eventType: string;
}

interface StatusPageDialogProps {
  finishedAt?: string;
  warnings: PlanningWarning[];
  open: boolean;
  onClose: () => void;
}

const Event = ({
  index,
  timestamp,
  detail,
}: {
  index?: number;
  timestamp: string;
  detail: string;
}) => (
  <Stack key={index} direction="row" gap={1}>
    {typeof index === "number" && (
      <Typography variant="body2">{`${index + 1}.`}</Typography>
    )}
    <Typography variant="body2">{`[${dayjs(timestamp).format("L, HH:mm:ss")}] ${detail}`}</Typography>
  </Stack>
);

export const StatusPageDialog = ({
  warnings,
  open,
  onClose,
}: StatusPageDialogProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll={"paper"}
    >
      <DialogTitle>Warnings</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          {warnings?.map((warning, index) => (
            <Event
              key={index}
              index={index}
              timestamp={warning.updatedAt}
              detail={warning.message}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface WarningBoxProps {
  onClick: () => void;
  numberOfWarnings: number;
}

interface WarningBoxContentsProps {
  onClick: () => void;
  numberOfWarnings: number;
  color: "warning" | "error";
}

const WarningBoxContents = ({
  numberOfWarnings,
  onClick,
  color,
}: WarningBoxContentsProps) => (
  <Stack direction="row" alignItems="center" gap={0.3}>
    <Typography variant="body2" color={`${color}.dark`}>
      {numberOfWarnings} warning{numberOfWarnings === 1 ? "" : "s"} detected and
      skipped
    </Typography>
    <Button
      onClick={onClick}
      sx={{ textDecoration: "underline", textTransform: "uppercase" }}
      size="small"
      color={color}
      variant="text"
    >
      VIEW DETAILS
    </Button>
  </Stack>
);

export const WarningBox = ({ onClick, numberOfWarnings }: WarningBoxProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ marginTop: 2, padding: 1, backgroundColor: "warning.light" }}
    >
      <WarningAmberOutlined color="warning" fontSize="small" />
      <Stack>
        <Typography variant="subtitle2" color="warning.dark">
          Warnings
        </Typography>
        <WarningBoxContents
          numberOfWarnings={numberOfWarnings}
          onClick={onClick}
          color="warning"
        />
      </Stack>
    </Stack>
  );
};

interface ErrorBoxProps {
  error: string;
  errorDate: string;
  numberOfWarnings: number;
  onClick: () => void;
}

export const ErrorBox = ({
  error,
  errorDate,
  numberOfWarnings,
  onClick,
}: ErrorBoxProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ marginTop: 2, padding: 1, backgroundColor: "error.light" }}
    >
      <ErrorOutline color="error" fontSize="small" sx={{ marginTop: 0.5 }} />
      <Stack>
        <Typography variant="subtitle1" color="error.dark">
          Plan failed
        </Typography>
        <Typography
          variant="body2"
          color="error.dark"
          sx={{ maxHeight: 150, overflow: "auto" }}
        >
          {`[${dayjs(errorDate).format("L, HH:mm:ss")}] ${error}`}
        </Typography>
        {numberOfWarnings > 0 && (
          <WarningBoxContents
            numberOfWarnings={numberOfWarnings}
            onClick={onClick}
            color="error"
          />
        )}
      </Stack>
    </Stack>
  );
};
