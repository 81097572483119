import { PageHeader, PatientDetails, TextOverflowTooltip } from "@components";
import { Skeleton, Stack, Typography } from "@mui/material";
import { OrderOut } from "@providers/hop-ord-server/api";
import { ReactNode } from "react";
interface Props {
  title: string;
  right?: ReactNode;
  orderData?: OrderOut;
  children?: ReactNode;
  customStatus?: string;
  showDivider?: boolean;
}

const OrderHeader = ({
  title,
  right,
  orderData,
  children,
  showDivider = false,
}: Props) => {
  const titleContent = orderData ? (
    <Stack
      data-testid="order-header-title"
      gap={1}
      direction="row"
      style={{ marginTop: "0!important" }}
    >
      <span>{title}</span>
    </Stack>
  ) : (
    <Skeleton variant="text" width={120} height={32} />
  );

  const ordername = !orderData ? (
    <Skeleton variant="text" width={300} height={32} />
  ) : (
    <TextOverflowTooltip variant="h6">
      {orderData.orderName === "New Order" ? "-" : orderData.orderName}
    </TextOverflowTooltip>
  );

  const patientDetails = !orderData ? (
    <Skeleton variant="text" width={162} height={24} />
  ) : (
    <Typography variant="subtitle2">
      <PatientDetails
        name={orderData.patientName}
        patId={orderData.patientMrn}
        dob={orderData.patientDob}
      />
    </Typography>
  );

  return (
    <>
      <PageHeader
        title={titleContent}
        right={
          <Stack direction="row" alignItems="baseline" spacing={2}>
            {right}
          </Stack>
        }
        showDivider={showDivider}
      >
        <Stack gap={1}>
          {ordername}
          {patientDetails}
          {children}
        </Stack>
      </PageHeader>
    </>
  );
};

export default OrderHeader;
