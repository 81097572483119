import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState } from "react";
import {
  IconButton,
  Drawer,
  Box,
  Typography,
  Divider,
  Badge,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useNotificationsApiV1GetActivityCountQuery,
  useNotificationsApiV1GetNotificationsQuery,
  useWebApiV1UpdateUserActivityLastViewedMutation,
} from "@providers/hop-ord-server/api";
import NotificationItem from "./NotificationItem";
import { NotificationOut } from "@providers/hop-ord-server/api";

const NO_NOTIFICATIONS = "No notifications from the last 30 days.";
const ALL_NOTIFICATIONS =
  "That's all your notifications from the last 30 days.";
const Notifications = () => {
  const [notificationPanelOpen, setNotificationPanelOpen] = useState(false);
  const { data } = useNotificationsApiV1GetNotificationsQuery({
    deviceToken: "-1",
  });
  const { data: activityCount } = useNotificationsApiV1GetActivityCountQuery();

  const [updateUserActivityLastViewed] =
    useWebApiV1UpdateUserActivityLastViewedMutation();

  const toggleDrawer = () => {
    setNotificationPanelOpen((prevState) => !prevState);
  };

  const handleClick = () => {
    if (!notificationPanelOpen) {
      updateUserActivityLastViewed();
    }
    toggleDrawer();
  };

  return (
    <>
      <IconButton
        data-testid="notification-icon"
        onClick={handleClick}
        color="inherit"
      >
        <Badge badgeContent={activityCount || 0} max={9}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={notificationPanelOpen}
        onClose={() => setNotificationPanelOpen(false)}
      >
        <Box
          sx={{
            width: 600,
            padding: "80px 16px 16px 16px",
          }}
        >
          <IconButton color="inherit" onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" pt="16px" pb="16px">
            Notifications
          </Typography>
          {data &&
            data.map((notification: NotificationOut) => (
              <NotificationItem
                notification={notification}
                key={notification.id}
                toggleDrawer={toggleDrawer}
              />
            ))}
          <Divider />
          <Typography
            variant="body2"
            pt="16px"
            pb="16px"
            textAlign="center"
            color="grey.700"
          >
            {data && data.length > 0 ? ALL_NOTIFICATIONS : NO_NOTIFICATIONS}
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default Notifications;
