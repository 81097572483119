import { PlanGenerationAttemptOut } from "@providers/hop-ord-server/api";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
  Chip,
  TableContainer,
} from "@mui/material";
import { toLocaleDateTimeString } from "@utils";

interface Props {
  plans: PlanGenerationAttemptOut[];
}

const CompletedPlans = ({ plans }: Props) => {
  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="h6">Completed Plans</Typography>
      <TableContainer sx={{ maxHeight: "400px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Automated template</TableCell>
              <TableCell>Related prescription</TableCell>
              <TableCell>Generated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans?.map((plan, index) => {
              const showChips = plan?.warnings?.length > 0 || plan?.error;
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Stack gap={1}>
                      <Typography variant="body2">
                        {plan.templateName}
                      </Typography>
                      {showChips && (
                        <Stack direction="row" alignItems="center" gap={1}>
                          {plan?.warnings?.length > 0 && (
                            <Chip
                              size="small"
                              variant="outlined"
                              label={`${plan.warnings.length} warnings`}
                              color="warning"
                            />
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack>
                      <Typography variant="body2">
                        {plan?.relatedPrescription || "-"}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {plan.machine}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {toLocaleDateTimeString(plan?.finishedAt || "")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CompletedPlans;
