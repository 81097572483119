import { Stack, TextField, InputAdornment, Button } from "@mui/material";
import {
  Search,
  KeyboardDoubleArrowRight,
  LocationCity,
  Person,
  FilterList,
  Clear,
  Add,
} from "@mui/icons-material";
import { FilterSelect } from "@components";
import {
  useProtocolApiV1ListDataQuery,
  useWebApiV1GetTenantPhysiciansQuery,
  ListDataOptionSerialised,
  UserOptionOut,
  useWebApiV1CreateOrderMutation,
  useWebApiV1GetUserFeatureSwitchesQuery,
} from "@providers/hop-ord-server/api";
import { Feature, ListDataCategory } from "@enums";
import { useNavigate } from "react-router-dom";
interface Props {
  searchText: string;
  setSearchText: (searchText: string) => void;
  selectedStatus: string[];
  setSelectedStatus: (selectedStatus: string[]) => void;
  selectedSite: string[];
  setSelectedSite: (selectedSite: string[]) => void;
  selectedPhysician: string[];
  setSelectedPhysician: (selectedPhysician: string[]) => void;
  selectedQuickFilters: string[];
  setSelectedQuickFilters: (selectedQuickFilters: string[]) => void;
}

const sortByName = (a: string, b: string) => a.localeCompare(b);

const STATUS_OPTIONS = [
  { label: "New prescriptions", value: "New prescriptions" },
  { label: "Ready for planning", value: "Ready for planning" },
  { label: "Planning (Automation)", value: "Planning (Automation)" },
  { label: "Handover complete", value: "Handover complete" },
];

const QUICK_FITLERS: { label: string; value: string }[] = [
  { label: "Test view", value: "Test view" },
];

const SearchAndFilterBar = ({
  searchText,
  setSearchText,
  selectedStatus,
  setSelectedStatus,
  selectedSite,
  setSelectedSite,
  selectedPhysician,
  setSelectedPhysician,
  selectedQuickFilters,
  setSelectedQuickFilters,
}: Props) => {
  const [addOrder] = useWebApiV1CreateOrderMutation();
  const navigate = useNavigate();
  const { data: treatingDepartments } = useProtocolApiV1ListDataQuery({
    categories: [ListDataCategory.TREATING_DEPARTMENT],
  });
  const { data: physicians } = useWebApiV1GetTenantPhysiciansQuery();
  const { data: featureSwitchData } = useWebApiV1GetUserFeatureSwitchesQuery();

  const showCreateOrderButton = featureSwitchData?.find(
    (feature) => feature.name === Feature.COMMAND_CENTER_CREATE_ORDER,
  );

  const createOrder = async () => {
    const order = await addOrder().unwrap();
    navigate(`/order/${order}`);
  };

  const siteOptions =
    treatingDepartments
      ?.map((listData: ListDataOptionSerialised) => ({
        value: listData?.value,
        label: listData?.value,
      }))
      .sort((a, b) => sortByName(a.label, b.label)) || [];
  const physicianOptions =
    physicians
      ?.map((physician: UserOptionOut) => ({
        label: physician?.name,
        value: String(physician?.id),
      }))
      .sort((a, b) => sortByName(a.label, b.label)) || [];

  const clearAllFilters = () => {
    setSelectedStatus([]);
    setSelectedSite([]);
    setSelectedPhysician([]);
    setSelectedQuickFilters([]);
  };

  const anyFilterSelected =
    selectedStatus.length > 0 ||
    selectedSite.length > 0 ||
    selectedPhysician.length > 0 ||
    selectedQuickFilters.length > 0;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {showCreateOrderButton !== undefined && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          sx={{ marginRight: 2 }}
          onClick={createOrder}
        >
          Create Careplan
        </Button>
      )}
      <TextField
        id="search-text"
        placeholder="Search patient name or ID"
        variant="standard"
        value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
        sx={{
          width: "250px",
          marginRight: 2,
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          },
        }}
      />
      <FilterSelect
        label="Status"
        options={STATUS_OPTIONS}
        selectedOptions={selectedStatus}
        setSelectedOptions={setSelectedStatus}
        startIcon={<KeyboardDoubleArrowRight />}
      />
      <FilterSelect
        label="Site"
        options={siteOptions}
        selectedOptions={selectedSite}
        setSelectedOptions={setSelectedSite}
        startIcon={<LocationCity />}
      />
      <FilterSelect
        label="Physician"
        options={physicianOptions}
        selectedOptions={selectedPhysician}
        setSelectedOptions={setSelectedPhysician}
        startIcon={<Person />}
      />
      <FilterSelect
        label="Quick Filters"
        options={QUICK_FITLERS}
        selectedOptions={selectedQuickFilters}
        setSelectedOptions={setSelectedQuickFilters}
        startIcon={<FilterList />}
      />
      {anyFilterSelected && (
        <Button
          disabled={!anyFilterSelected}
          startIcon={<Clear />}
          onClick={clearAllFilters}
        >
          Clear Filters
        </Button>
      )}
    </Stack>
  );
};

export default SearchAndFilterBar;
