import {
  OrderOut,
  OrderPlanGenerationAttemptOut,
  PlanningProgress,
  PlanningWarning,
} from "@providers/hop-ord-server/api";

import dayjs from "dayjs";

export interface AutomationState {
  startTime: dayjs.Dayjs | null;
  allSteps: string[];
  warnings: PlanningWarning[];
  error: string;
  completedTime: dayjs.Dayjs | null;
  planProgressPercentage: number;
  currentStep: string;
  currentStepIndex: number;
  elapsedTimeSubtext: string;
  currentStatus: AutomationStatus;
}

export type OrderWithAutomationState = OrderOut & {
  automationState: AutomationState;
};

export type AutomationStatus =
  | "success"
  | "warning"
  | "error"
  | "in_progress"
  | "not_started";

export const getAutomationStatus = (
  progress?: PlanningProgress | OrderPlanGenerationAttemptOut | null,
): AutomationStatus => {
  if (progress?.result?.success) {
    return "success";
  }
  if (progress?.result?.error) {
    return "error";
  }
  if (progress?.planningStartedAt) {
    return "in_progress";
  }
  // TODO: can it ever be warning?
  if (progress?.warnings?.length) {
    return "warning";
  }
  return "not_started";
};

export const getElapsedTimeSubtext = (status: AutomationStatus): string => {
  switch (status) {
    case "success":
      return "Completed | Elapsed time:";
    case "error":
      return "Failed | Elapsed time:";
    case "not_started":
      return "Queued";
    default:
      return "Processing | Elapsed time:";
  }
};

export const getPlanProgressPercentage = (
  stepSequence: string[],
  step: string,
) => {
  const stepIndex = stepSequence.indexOf(step);
  if (stepIndex === -1) {
    return 0;
  }
  return ((stepIndex + 1) / stepSequence.length) * 100;
};

export const getStatusColour = (status: AutomationStatus) => {
  if (status === "success") {
    return "success";
  }

  if (status === "error") {
    return "error";
  }

  if (status === "in_progress") {
    return "secondary";
  }

  return "inherit";
};

export const augmentOrderWithAutomationState = (
  order: OrderOut,
): OrderWithAutomationState => {
  const latestGenAttempt = order.latestPlanGenerationAttempt;
  if (!latestGenAttempt) {
    return {
      ...order,
      automationState: {
        allSteps: [],
        completedTime: null,
        currentStatus: "not_started",
        currentStep: "",
        currentStepIndex: -1,
        elapsedTimeSubtext: "",
        error: "",
        planProgressPercentage: 0,
        startTime: null,
        warnings: [],
      },
    };
  }

  const status = getAutomationStatus(latestGenAttempt);
  const elapsedTimeSubtext = getElapsedTimeSubtext(status);
  const planProgressPercentage = getPlanProgressPercentage(
    latestGenAttempt.stepSequence,
    latestGenAttempt.currentStep,
  );
  const currentStepIndex = latestGenAttempt.stepSequence.indexOf(
    latestGenAttempt.currentStep,
  );

  const automationState: AutomationState = {
    startTime: latestGenAttempt.planningStartedAt
      ? dayjs(latestGenAttempt.planningStartedAt)
      : null,
    allSteps: latestGenAttempt.stepSequence,
    warnings: latestGenAttempt.warnings || [],
    error: latestGenAttempt.result?.error || "",
    completedTime: latestGenAttempt.result?.finishedAt
      ? dayjs(latestGenAttempt.result?.finishedAt)
      : null,
    planProgressPercentage: planProgressPercentage,
    currentStep: latestGenAttempt.currentStep,
    currentStepIndex: currentStepIndex,
    elapsedTimeSubtext: elapsedTimeSubtext,
    currentStatus: status,
  };

  return { ...order, automationState: automationState };
};
