import { Stack, Alert, Divider } from "@mui/material";
import {
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1OrderQuery,
  OrderOut,
} from "@providers/hop-ord-server/api";
import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import OrderHeader from "@components/OrderHeader/OrderHeader";
import { toLocaleDateTimeString } from "@utils";
import OrderTrackerDrawer, {
  PanelType,
  DEFAULT_DRAWER,
} from "../OrderTracker/components/OrderTrackerDrawer/OrderTrackerDrawer";
import { ActionIcons } from "../OrderTracker/components";
import PortaledMainBanner from "@pages/Layout/PortaledMainBanner";
import WarningIcon from "@mui/icons-material/Warning";
import PlanningAutomation from "../OrderTracker/components/PlanningAutomation/PlanningAutomation";

const PAGE_WIDTH = "900px"; // Need to enforce the width so the PDF generation is consistent

export const pdfName = (orderData: OrderOut): string => {
  // The default filename is <first name>_<last name>_<DOB in yyyymmdd>_<MRN if exists>_<order name with _ instead of spaces and - instead of />.PDF
  // e.g. John_Doe_19500612_A1276924_Lung_VMAT_60.0-30.PDF
  const patientName = orderData?.patientName?.split(" ").join("_");
  const orderName = orderData?.orderName
    ?.split(" ")
    .join("_")
    .split("/")
    .join("-");
  const patientDob = orderData?.patientDob
    ? new Date(orderData?.patientDob)
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "")
    : "";
  const patId = orderData?.patientMrn;
  return `${patientName}_${patientDob}${patId ? `_${patId}` : ""}_${orderName}.pdf`;
};

const DemoDosiOrderTrackerPage = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const { data: orderData, isLoading: orderLoading } = useWebApiV1OrderQuery({
    orderId: Number(orderId),
  });
  const { data: siteGroupData, isLoading: siteGroupLoading } =
    useWebApiV1ListSiteGroupsQuery({
      orderId: Number(orderId),
    });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDrawerPanel, setSelectedDrawerPanel] =
    useState<PanelType>(DEFAULT_DRAWER);

  const editingDatetime = orderData?.editingDatetime
    ? toLocaleDateTimeString(orderData?.editingDatetime || "")
    : "";

  const pageTitle = location.pathname.endsWith("/automated")
    ? "Plan Tracking"
    : "Plan Generation";

  if (orderLoading || siteGroupLoading) {
    return <></>;
  }

  return (
    <>
      {orderData && !orderData?.isLatest && (
        <PortaledMainBanner>
          <Stack>
            <Alert
              severity="warning"
              sx={{ justifyContent: "center" }}
              icon={<WarningIcon />}
            >
              {`Dr ${orderData?.treatingRo} began editing this order at ${editingDatetime} and has not yet submitted the changes.`}
            </Alert>
            <Divider />
          </Stack>
        </PortaledMainBanner>
      )}
      <Stack
        maxWidth={PAGE_WIDTH}
        width="100%"
        marginLeft="auto"
        marginRight="auto"
      >
        <OrderHeader
          title={pageTitle}
          showDivider={true}
          orderData={orderData}
          right={
            <ActionIcons
              drawerOpen={drawerOpen}
              orderData={orderData}
              setSelectedPanel={setSelectedDrawerPanel}
              setDrawerOpen={setDrawerOpen}
              isRo={false}
            />
          }
        />
        {orderData && <PlanningAutomation order={orderData} />}
      </Stack>
      <OrderTrackerDrawer
        orderId={Number(orderId)}
        open={drawerOpen}
        setOpen={setDrawerOpen}
        selectedPanel={selectedDrawerPanel}
        setSelectedPanel={setSelectedDrawerPanel}
        orderData={orderData}
        siteGroupData={siteGroupData || []}
      />
    </>
  );
};

export default DemoDosiOrderTrackerPage;
